<template>
  <v-card class="pa-2 fill-height" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden; display: flex; flex-direction: column">
    <v-card-title class="pb-2" style="display: flex;">
      <span>{{ $lang.header.addConnectedResources }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters class="px-2 pb-2" style="flex: unset">
      <v-btn
        :color="all ? 'primary' : 'grey'"
        @click="selectAll()"
      >
        {{ $lang.labels.all }}
      </v-btn>
      <v-btn
        class="ml-2"
        :color="none ? 'primary' : 'grey'"
        @click="selectNone()"
      >
        {{ $lang.labels.none }}
      </v-btn>
    </v-row>
    <v-row no-gutters class="px-2" style="flex: unset">
      <v-divider />
    </v-row>
    <div style="height: 100% !important; width: 100%; display: flex; flex-grow: 1;">
      <v-row no-gutters style="max-height: calc(80vh - 209px) !important; overflow-y: auto">
        <v-col cols="12" class="px-2">
          <template v-for="(item, i) in innerData">
            <v-switch :key="i" v-model="item.isSelected" :label="`${$lang.status[item.type]} - ${item.data.name}`">
              <template v-slot:label>
                <v-icon class="mr-1">{{ item.icon }}</v-icon>
                {{ $lang.status[item.type] }} - {{ item.data.name }}
              </template>
            </v-switch>
          </template>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="py-4" style="display: flex;">
      <v-row justify="space-between">
        <v-btn
          color="error"
          text
          large
          @click="$emit('closeDialog', true)"
        >
          {{ $lang.actions.cancel }}
        </v-btn>
        <v-btn
          color="primary"
          text
          large
          @click="$emit('save', innerData.filter(x => x.isSelected)); $emit('closeDialog', true)"
        >
          {{ $lang.actions.add }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      innerData: [],
      all: false,
      none: false
    }
  },
  watch: {
    innerData: {
      handler (val) {
        const checkerTrue = (arr) => arr.every((v) => v.isSelected === true)
        const checkerFalse = (arr) => arr.every((v) => v.isSelected === false)

        this.all = checkerTrue(val)
        this.none = checkerFalse(val)
      },
      deep: true
    }
  },
  created() {
    this.innerData = this.data
  },
  methods: {
    selectAll() {
      this.none = false
      this.innerData.forEach((x) => x.isSelected = true)
    },
    selectNone() {
      this.all = false
      this.innerData.forEach((x) => x.isSelected = false)
    }
  }
}
</script>
<style lang="scss">
#data-text {
  height: 57vh !important;
}
</style>
