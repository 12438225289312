<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-row wrap no-gutters>
        <v-col cols="10" class="pr-1">
          <v-autocomplete
            :key="`${type}-add`"
            v-model="selectedFromBE"
            outlined
            dense
            :items="listFromBE"
            :loading="isLoading"
            :search-input.sync="searchFromBE"
            clearable
            hide-no-data
            item-text="name"
            item-value="id"
            :label="`${$lang.status[type]} ${$lang.labels.global}`"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
            :readonly="!canEdit"
            :data-cy="`module-search-${type}`"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" class="pl-1">
          <v-btn
            block
            color="accent"
            class="mr-1 color-accent-text"
            :data-cy="`module-add-${type}`"
            @click="addFromBeToModule()"
          >{{ $lang.actions.add }}</v-btn>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            :key="`${type}-select`"
            v-model="selectedFromModule"
            outlined
            dense
            :items="listFromModule"
            small-chips
            multiple
            hide-no-data
            hide-details
            deletable-chips
            hide-selected
            item-text="name"
            item-value="id"
            :label="`${$lang.status[type]} ${$lang.labels.local}`"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
            :readonly="!canEdit"
            :data-cy="`module-selected-group-${type}`"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                :data-cy="`module-selected-${type}`"
                @click="data.select; openItem(data.item)"
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-if="showModal" v-model="showModal" max-width="71%" persistent>
      <add-modal :data="dataForModal" @save="afterModal($event)" @closeDialog="showModal = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import {
  getModuleByIdUsingGET as getModule, getModulesUsingGET as getModules,
  getProcessByIdUsingGET as getProcess,
  getProcessCredentialByIdUsingGET as getCredential,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessesUsingGET as getProcesses,
  getSettingByIdUsingGET as getSetting, getSettingsUsingGET as getSettings,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplatesUsingGET as getTemplates, getTranslationsUsingGET as getTranslations,
  getTranslationUsingGET as getTranslation,
  getTriggerCronByIdUsingGET as getCron, getTriggerCronsUsingGET as getCrons,
  getTriggerRestByIdUsingGET as getRest, getTriggerRestsUsingGET as getRests,
  getValidationRuleByIdUsingGET as getValidationRule, getValidationRulesUsingGET as getValidationRules,
  getPluginByIdUsingGET as getPlugin, getPluginsUsingGET as getPlugins,
  getTriggerMessagingsUsingGET as getMessagings, getTriggerMessagingByIdUsingGET as getMessaging,
  getLibrariesUsingGET as getLibraries, getLibraryByIdUsingGET as getLibrary
} from '@/utils/api'
import AddModal from './AutoAddModal'

export default {
  components: {
    AddModal
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    allResources: {
      type: Array,
      default: () => []
    },
    defaultValues: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      isLoading: false,
      listFromBE: [],
      selectedFromBE: '',
      searchFromBE: '',
      listFromModule: [],
      selectedFromModule: [],
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        SETTING: 'settings/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        LIBRARY: 'libraries/edit'
      },
      showModal: false,
      dataForModal: []
    }
  },
  watch: {
    selectedFromModule: {
      handler(val) {
        this.$emit('setValues', val ? val : null)
      },
      deep: true
    },
    defaultValues: {
      handler(val) {
        if (val) this.values = this.defaultValues
      },
      deep: true
    },
    searchFromBE: {
      handler(val) {
        if (val && val.length > 1) {
          switch (this.type) {
          case 'GLOBAL_TRANSLATION':
            this.searchTranslationsFunction(val)
            break
          case 'MODULE':
            this.searchModulesFunction(val)
            break
          case 'PROCESS':
            this.searchProcessesFunction(val)
            break
          case 'PROCESS_CREDENTIAL':
            this.searchCredentialsFunction(val)
            break
          case 'SETTING':
            this.searchSettingsFunction(val)
            break
          case 'TEXT_TEMPLATE':
            this.searchTextTemplatesFunction(val)
            break
          case 'TRIGGER_CRON':
            this.searchCronsFunction(val)
            break
          case 'TRIGGER_REST':
            this.searchRestsFunction(val)
            break
          case 'VALIDATION_RULE':
            this.searchValidationRulesFunction(val)
            break
          case 'PLUGIN':
            this.searchPluginsFunction(val)
            break
          case 'TRIGGER_MESSAGING':
            this.searchMessagingsFunction(val)
            break
          case 'LIBRARY':
            this.searchLibrariesFunction(val)
            break
          default:
          }
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    openItem(item) {
      const link = `/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[this.type]}/${item.id}`

      window.open(link, '_blank')
    },
    remove (item) {
      const index = this.selectedFromModule.map((x) => x.id).indexOf(item.id)

      if (index >= 0) this.selectedFromModule.splice(index, 1)
      if (index >= 0) this.$emit('makeDirty', true)
    },
    async addFromBeToModule() {
      if (!this.selectedFromBE) return

      const tempSelected = this.listFromBE.find((x) => x.id === this.selectedFromBE)

      if (this.selectedFromModule.map((x) => x.id).includes(tempSelected.id)) return

      if (['TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING'].includes(this.type)) {
        const toAddArray = []
        const existingProcessesIds = this.allResources.filter((x) => x.type === 'PROCESS').map((y) => y.id)
        const existingValidationsIds = this.allResources.filter((x) => x.type === 'VALIDATION_RULE').map((y) => y.id)

        if (tempSelected.processId && !existingProcessesIds.includes(tempSelected.processId)) {
          getProcess({ id: tempSelected.processId })
            .then((res) => {
              toAddArray.push({ type: 'PROCESS', data: res.data.data, isSelected: false, icon: 'mdi-console-network' })
              this.sendDataEmit(tempSelected)
              if (this.type === 'TRIGGER_REST') {
                getRest({ id: tempSelected.id })
                  .then((res2) => {
                    if (res2.data.data && res2.data.data.validationRules.length > 0) {
                      res2.data.data.validationRules.forEach((validation) => {
                        if (!existingValidationsIds.includes(validation.validationRule.id)) toAddArray.push({
                          type: 'VALIDATION_RULE',
                          data: validation.validationRule,
                          isSelected: false,
                          icon: 'mdi-magnify-scan'
                        })
                      })
                      if (toAddArray.length > 0) {
                        this.dataForModal = toAddArray
                        this.showModal = true
                      }
                    }
                  })
                  .catch((err) => {
                    this.err = err
                  })
              } else if (this.type === 'TRIGGER_MESSAGING') {
                getCredential({ id: tempSelected.credentialId })
                  .then((res2) => {
                    toAddArray.push({ type: 'PROCESS_CREDENTIAL', data: res.data.data, isSelected: false, icon: 'mdi-lock' })
                    if (toAddArray.length > 0) {
                      this.dataForModal = toAddArray
                      this.showModal = true
                    }
                  })
                  .catch((err) => {
                    this.err = err
                  })
              } else {
                if (toAddArray.length > 0) {
                  this.dataForModal = toAddArray
                  this.showModal = true
                }
              }
            })
            .catch((err) => {
              this.err = err
            })
        } else if (this.type === 'TRIGGER_REST') {
          this.sendDataEmit(tempSelected)
          getRest({ id: tempSelected.id })
            .then((res2) => {
              if (res2.data.data && res2.data.data.validationRules.length > 0) {
                res2.data.data.validationRules.forEach((validation) => {
                  if (!existingValidationsIds.includes(validation.validationRule.id)) toAddArray.push({
                    type: 'VALIDATION_RULE',
                    data: validation.validationRule,
                    isSelected: false,
                    icon: 'mdi-magnify-scan'
                  })
                })
                if (toAddArray.length > 0) {
                  this.dataForModal = toAddArray
                  this.showModal = true
                } else {
                  this.sendDataEmit(tempSelected)
                }
              }
            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.sendDataEmit(tempSelected)
        }
      } else if (this.type === 'PROCESS') {
        getProcess({ id: tempSelected.id })
          .then((res) => {
            this.loopStepsGlobal(res.data.data)
          })
          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            console.log(err)
          })
      } else if (this.type === 'MODULE') {
        if (tempSelected?.resources?.length > 0) {
          this.loopModalResources(tempSelected.resources)
        }
      } else if (this.type === 'VALIDATION_RULE') {
        this.addValidationGlobal(tempSelected)
      } else {
        this.sendDataEmit(tempSelected)
      }
    },
    async fetchValidationRecursive(validation, isIdOnly = false) {
      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      const fetchedValidations = []
      const tempValidation = (validationId) => {
        getValidationRule({ id: validationId }).then((res) => {
          fetchedValidations.push({
            type: 'VALIDATION_RULE',
            data: res.data.data,
            isSelected: false,
            icon: this.iconHandler('VALIDATION_RULE')
          })
          if (res.data.data.includes && res.data.data.includes.length > 0) {
            res.data.data.includes.forEach((include) => {
              tempValidation(include)
            })
          }
        })
          .catch((err) => {
            console.log(err)
          })
      }

      tempValidation(isIdOnly ? validation : validation.id)

      await pause(1200)

      return fetchedValidations
    },
    async fetchModuleResource(resource) {
      return new Promise((resolve, reject) => {
        switch (resource.type) {
        case 'GLOBAL_TRANSLATION':
          getTranslation({ id: resource.id })
            .then((res) => {
              resolve({ type: resource.type, data: res.data.data })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'MODULE':
          getModule({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS':
          getProcess({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS_CREDENTIAL':
          getCredential({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'SETTING':
          getSetting({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TEXT_TEMPLATE':
          getTemplate({ templateId: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_CRON':
          getCron({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_REST':
          getRest({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'VALIDATION_RULE':
          this.fetchValidationRecursive(resource)
            .then((res) => {
              resolve(res)
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PLUGIN':
          getPlugin({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_MESSAGING':
          getMessaging({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'LIBRARY':
          getLibrary({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        default:
        }
      })
    },
    async addValidationGlobal(resource) {
      this.fetchValidationRecursive(resource)
        .then((result) => {
          const tempFilter = result.filter((x) => x !== false && x.id !== resource.id).flat(Infinity).filter((x) => x.data.id !== resource.id)

          this.sendDataEmit(resource)
          if (tempFilter.length > 0) {
            this.dataForModal = tempFilter
            this.showModal = true
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          console.log(error)
        })
    },
    iconHandler(type) {
      switch (type) {
      case 'PROCESS':
        return 'mdi-console-network'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'MODULE':
        return 'mdi-briefcase-edit-outline'
      case 'SETTING':
        return 'mdi-cog'
      case 'TRIGGER_MESSAGING':
        return 'mdi-web'
      case 'LIBRARY':
        return 'mdi-library-outline'
      default:
        return 'mdi-home'
      }
    },
    async loopModalResources(resources) {
      const fetchedResources = resources.map((resource) => {
        return this.fetchModuleResource(resource)
      })

      await Promise.all(fetchedResources)
        .then((result) => {
          const tempFilter = result.filter((x) => x !== false).flat(Infinity)

          if (tempFilter.length > 0) {
            this.dataForModal = tempFilter
            this.showModal = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async loopStepsGlobal(process) {
      const interestingSteps = []

      const loopSteps = (items) => {
        items.forEach((item) => {

          if (this.doWeNeedStep(item)) {
            interestingSteps.push(item)
          }

          if (item.properties && item.properties.steps && item.properties.steps.length > 0) {
            loopSteps(item.properties.steps)
          }
          if (item.properties && item.properties.conditions && item.properties.conditions.length > 0) {
            loopSteps(item.properties.conditions)
            item.properties.conditions.forEach((item2) => {
              loopSteps(item2.steps)
            })
          }
        })
      }

      await loopSteps(process.steps.steps)

      this.sendDataEmit(process)

      let foundValidations = null

      if (process.validationRuleId) {
        foundValidations = await this.fetchValidationRecursive(process.validationRuleId, true)
      }

      if (interestingSteps.length > 0 || foundValidations) this.formatInterestingSteps(interestingSteps, foundValidations)
    },
    async formatInterestingSteps(steps, foundValidations) {
      const toAddArray = []

      if (foundValidations) {
        foundValidations.forEach((validation) => {
          toAddArray.push(validation)
        })
      }

      const existingProcessesIds = this.allResources.filter((x) => x.type === 'PROCESS').map((y) => y.id)
      const existingTemplatesIds = this.allResources.filter((x) => x.type === 'TEXT_TEMPLATE').map((y) => y.id)
      const existingPluginsIds = this.allResources.filter((x) => x.type === 'PLUGIN').map((y) => y.id)
      const existingCredentialsIds = this.allResources.filter((x) => x.type === 'PROCESS_CREDENTIAL').map((y) => y.id)
      const existingSettingsIds = this.allResources.filter((x) => x.type === 'PROCESS_SETTING').map((y) => y.id)

      const formattedSteps = steps.map(async (step) => {
        const formattedStep = {}

        if (step.properties?.processName) formattedStep.processBe = await this.fetchProcess(step.properties.processName)
        if (step.properties?.textTemplate) formattedStep.textTemplateBe = await this.fetchTextTemplate(step.properties.textTemplate)
        if (step.properties?.pluginName) formattedStep.pluginBe = await this.fetchPlugin(step.properties.pluginName)
        if (step.properties?.credentialName) formattedStep.credentialBe = await this.fetchCredential(step.properties.credentialName)
        if (step.properties?.processSettingName) formattedStep.processSettingBe = await this.fetchSettings(step.properties.processSettingName)

        return formattedStep
      })

      await Promise.all(formattedSteps)
        .then((result) => {
          result.forEach((step) => {
            const toAddArrayIds = toAddArray.map((x) => x.data.id)

            if (step.processBe && !existingProcessesIds.includes(step.processBe.id) && !toAddArrayIds.includes(step.processBe.id)) toAddArray.push({
              type: 'PROCESS',
              data: step.processBe,
              isSelected: false,
              icon: 'mdi-console-network'
            })
            if (step.textTemplateBe && !existingTemplatesIds.includes(step.textTemplateBe.id) && !toAddArrayIds.includes(step.textTemplateBe.id)) toAddArray.push({
              type: 'TEXT_TEMPLATE',
              data: step.textTemplateBe,
              isSelected: false,
              icon: 'mdi-file-document-box'
            })
            if (step.pluginBe && !existingPluginsIds.includes(step.pluginBe.id) && !toAddArrayIds.includes(step.pluginBe.id)) toAddArray.push({
              type: 'PLUGIN',
              data: step.pluginBe,
              isSelected: false,
              icon: 'mdi-puzzle'
            })
            if (step.credentialBe && !existingCredentialsIds.includes(step.credentialBe.id) && !toAddArrayIds.includes(step.credentialBe.id)) toAddArray.push({
              type: 'PROCESS_CREDENTIAL',
              data: step.credentialBe,
              isSelected: false,
              icon: 'mdi-lock'
            })
            if (step.processSettingBe && !existingSettingsIds.includes(step.processSettingBe.id) && !toAddArrayIds.includes(step.processSettingBe.id)) toAddArray.push({
              type: 'PROCESS_SETTING',
              data: step.processSettingBe,
              isSelected: false,
              icon: 'mdi-cog'
            })
          })
          if (toAddArray.length > 0) {
            this.dataForModal = toAddArray
            this.showModal = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchSettings(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getSettings(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchCredential(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getCredentials(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchProcess(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getProcesses(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchTextTemplate(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getTemplates(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchPlugin(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getPlugins(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchLibrary(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getLibraries(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    doWeNeedStep(item) {
      return !!(['EXECUTE_PROCESS', 'PROCESS_SETTING'].includes(item.type) || item.properties?.processName || item.properties?.textTemplate || item.properties?.pluginName  || item.properties?.credentialName)
    },
    afterModal(data) {
      if (data.length > 0) {
        data.forEach((item) => {
          this.sendAdditionalDataEmit(item.type, item.data.id)
        })
      }
    },
    sendAdditionalDataEmit(type, tempSelected) {
      this.$emit('setAdditionalValues', { type, data: tempSelected })
    },
    sendDataEmit(tempSelected) {
      if (tempSelected) this.listFromModule.push(tempSelected)
      if (tempSelected) this.selectedFromModule.push(tempSelected)
      this.$emit('makeDirty', true)
    },
    init() {

      switch (this.type) {
      case 'GLOBAL_TRANSLATION':
        this.defaultValues.forEach((entry) => {
          getTranslation({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'MODULE':
        this.defaultValues.forEach((entry) => {
          getModule({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'PROCESS':
        this.defaultValues.forEach((entry) => {
          getProcess({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'PROCESS_CREDENTIAL':
        this.defaultValues.forEach((entry) => {
          getCredential({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'SETTING':
        this.defaultValues.forEach((entry) => {
          getSetting({ id: entry.id })
            .then((res) => {
              const tempData = res.data.data

              this.addDataToModule(tempData)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'TEXT_TEMPLATE':
        this.defaultValues.forEach((entry) => {
          getTemplate({ templateId: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'TRIGGER_CRON':
        this.defaultValues.forEach((entry) => {
          getCron({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'TRIGGER_REST':
        this.defaultValues.forEach((entry) => {
          getRest({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'VALIDATION_RULE':
        this.defaultValues.forEach((entry) => {
          getValidationRule({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'PLUGIN':
        this.defaultValues.forEach((entry) => {
          getPlugin({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'TRIGGER_MESSAGING':
        this.defaultValues.forEach((entry) => {
          getMessaging({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      case 'LIBRARY':
        this.defaultValues.forEach((entry) => {
          getLibrary({ id: entry.id })
            .then((res) => {
              this.addDataToModule(res.data.data)
            })
            .catch((err) => {
              this.err = err
            })
        })
        break
      default:
      }
    },
    addDataToModule(data) {
      this.listFromModule.push(data)
      this.selectedFromModule.push(data)
    },
    searchTranslationsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTranslations(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchModulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getModules(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchProcessesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getProcesses(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchCredentialsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCredentials(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchSettingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getSettings(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchTextTemplatesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTemplates(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchCronsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCrons(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchRestsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getRests(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchValidationRulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getValidationRules(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchPluginsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getPlugins(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchMessagingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getMessagings(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    },
    searchLibrariesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getLibraries(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.err = err
        })
    }
  }
}
</script>
